import { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Alert, AlertTitle, Autocomplete, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Popover, TextField, Tooltip, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from '../iconify';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import React from 'react';
import { useCampaignInfoShoppingQuery, useCheckShoppingExistsMutation, useGetActiveModeQuery, useGetAdGroupKoBiddingQuery, useGetAdGroupShoppingQuery, useGetCampaignsQuery, useGetCampaignsShoppingQuery, useGetCountriesKoBiddingQuery, useGetCountriesShoppingQuery, useGetKeywordInfoKoBiddingQuery, useGetKeywordsKoBiddingQuery, useGetMatchTypeKoBiddingQuery, useGetProfileKoBiddingQuery, useGetProfileShoppingQuery } from 'src/context/api/liveBidding/api';
import { formatUpdatedAt } from 'src/utils/dates-labels';
import { useLocales } from 'src/locales';

// Typing for country and other data structures
type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean; // Added to indicate duplicates
};

const CampaignEditInputCell = ({ id, value, field, api, row }: any) => {
  const workspace: any = useCurrentWorkspace();

  const profile = row.account;
  const platform = row.platform;

  // Récupérer les options de campagnes via l'API
  const { data: campaignOptions, isLoading } = useGetCampaignsShoppingQuery({
    client: workspace?.bqTable,
    profile,
    platform
  });

  const handleCampaignChange = (event: any, newValue: any) => {
    // Convertir la nouvelle valeur en tableau même si elle est unique
    const selectedCampaign = newValue ? [newValue.campaign_name] : [];
    api.setEditCellValue({ id, field: 'campaign_name', value: selectedCampaign }); // Stocker en tableau
    api.stopCellEditMode({ id, field });
  };

  // Trouver la campagne sélectionnée pour l'affichage (prend la première valeur du tableau)
  const selectedCampaign = campaignOptions?.find((opt: any) => opt.campaign_name === value?.[0]) || null;

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        disableClearable
        options={campaignOptions || []}
        getOptionLabel={(option) => option.campaign_name || ''}
        value={selectedCampaign}
        onChange={handleCampaignChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.campaign_name === value?.campaign_name}
      />
    </Box>
  );
};

const AccountEditInputCell = ({ id, field, row, value, profileKoBiddingOptionsRef }: any) => {
  const apiRef = useGridApiContext();
  const workspace: any = useCurrentWorkspace();

  const platform = row.platform;

  // Récupérer les options de profils via l'API pour le compte
  const { data: profileKoBiddingOptions, isLoading: isLoadingProfileKoBidding } = useGetProfileShoppingQuery({
    client: workspace?.bqTable,
    platform,
  });

  // Mettre à jour la référence lorsque profileKoBiddingOptions change
  useEffect(() => {
    if (profileKoBiddingOptions) {
      profileKoBiddingOptionsRef.current = profileKoBiddingOptions; // Met à jour la référence
    }
  }, [profileKoBiddingOptions]);

  const handleChange = useCallback(
    (event: any, newValue: any) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field: 'account', value: newValue.profile }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={profileKoBiddingOptions || []}  // Options provenant de l'API
        loading={isLoadingProfileKoBidding}
        getOptionLabel={(option) => option.profile || ''}  // Utiliser la clé `profile` pour l'affichage
        value={profileKoBiddingOptions?.find((option: any) => option.profile === value) || null}  // Trouver la valeur correspondante
        onChange={handleChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select account" size="small" />
        )}
      />
    </Box>
  );
};

const MaxCPCPortfolioStrategyEditInputCell = ({ id, field, value, row }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string | number>(value?.toFixed(2) || '');

  // Définition de la valeur minimale selon la plateforme
  const platform = row.platform;
  const minValue = platform === 'Bing Ads' ? 0.05 : 0.01;
  const maxValue = 50;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
    },
    []
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (isNaN(newValue) || newValue < minValue || newValue > maxValue) {
        setError(`Max CPC must be between ${minValue} and ${maxValue}`);
      } else {
        setError(null);
        apiRef.current.setEditCellValue(
          { id, field, value: parseFloat(newValue.toFixed(2)) },
          event
        );
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field, minValue, maxValue]
  );

  const handleUnset = () => {
    setInputValue(''); // Réinitialiser l'affichage du champ
    apiRef.current.setEditCellValue({ id, field, value: null }); // Définir la valeur comme `null`
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TextField
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        variant="outlined"
        size="small"
        type="number"
        inputProps={{
          step: "0.01",
          min: minValue.toString(),
          max: maxValue.toString()
        }}
        InputProps={{
          endAdornment: (
            <>
              {value !== 0 && (
                <InputAdornment
                  sx={{ position: "relative", left: 10 }}
                  position="end"
                >
                  <Button
                    sx={{
                      height: 24,
                      minWidth: 36,
                      padding: '2px 8px',
                    }}
                    variant="contained"
                    color="inherit"
                    onClick={handleUnset}
                    size="small"
                  >
                    <Typography variant="caption">unset</Typography>
                  </Button>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </Box>
  );
};

const TargetROASEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string | number>(value?.toFixed(2) || '');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue); // Mise à jour de l'affichage en tant que chaîne de caractères
    },
    []
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (isNaN(newValue) || newValue < 1 || newValue > 5001) {
        setError("Target ROAS must be between 1 and 5000");
      } else {
        setError(null);
        apiRef.current.setEditCellValue({ id, field, value: newValue.toFixed(2) }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <TextField
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        variant="outlined"
        size="small"
        type="number"
        inputProps={{
          step: "0.01", // Autorise les valeurs décimales
        }}
      />
    </Box>
  );
};


const AdGroupEditInputCell = ({ id, value, field, api, row, adGroupOptionsRef }: any) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de groupes d'annonces via l'API
  const { data: adGroupOptionsKoBidding, isLoading: isLoadingAdGroupKoBidding } = useGetAdGroupShoppingQuery({
    client: workspace?.bqTable,
    profile: row.account,
    campaign: row.campaign_name,
    platform: row?.platform
  });

  useEffect(() => {
    if (adGroupOptionsKoBidding) {
      adGroupOptionsRef.current = adGroupOptionsKoBidding;  // Mettre à jour la référence des groupes d'annonces
    }
  }, [adGroupOptionsKoBidding]);

  const handleAdGroupChange = (event: any, newValue: any) => {
    // Mettre à jour la valeur des ad_group_name et ad_group_id
    api.setEditCellValue({ id, field: 'ad_group_name', value: newValue?.ad_group_name });
    api.stopCellEditMode({ id, field });
  };

  const selectedAdGroup = adGroupOptionsKoBidding?.find(
    (opt: any) => opt.ad_group_name === value
  ) || null;

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={adGroupOptionsKoBidding || []}
        getOptionLabel={(option) => option.ad_group_name || ''}
        value={selectedAdGroup}
        onChange={handleAdGroupChange}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select ad group" />
        )}
        size="small"
        isOptionEqualToValue={(option, value) => option.ad_group_id === value?.ad_group_id}
      />
    </Box>
  );
};

const CampaignCell = (params: GridRenderCellParams) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isDuplicate = params.row.isDuplicate;

  const campaign = params.row.campaign_name;
  const ad_group = params.row.ad_group_name;
  const account = params.row.account;

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Chip
          size="small"
          label={
            params.row.isDuplicate
              ? `🚫 ${params.row.campaign_name}`
              : params.row.campaign_name
          }
          style={{ margin: 2 }}
        />

      </div>
      {isDuplicate && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            width: 700,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography>Unable to add the following campaign: {campaign}.</Typography>
            <Typography fontSize={14} sx={{ mt: 1 }}>A configuration already exists for the campaign '{campaign}', account '{account}', ad group '{ad_group}'.</Typography>
          </Box>
        </Popover>
      )}
    </>
  );
};

const PlatformEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={["Google Ads", "Bing Ads"]}
        value={value || ""}
        onChange={handleChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select platform" size="small" />
        )}
      />
    </Box>
  );
};


const ModeEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={["KO Bidding Manual", "KO Bidding Blended CTR", "KO Bidding Ad CTR"]}
        value={value || ""}
        onChange={handleChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select mode" size="small" />
        )}
      />
    </Box>
  );
};

// Create columns
const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  profileKoBiddingOptionsRef: React.RefObject<any>,
  currentLang: any,
  rows: any,
  adGroupOptionsRef: React.RefObject<any>,
  editedRows: Map<number | string, Partial<GridRowModel>>
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu params={params} editedRow={editedRows.has(params.id)} deleteItem={deleteItem} resetItem={resetItem} />
      ),
    },
    {
      field: 'platform',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.platform))), // Dynamically fetch unique values
      headerName: 'Platform',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <PlatformEditInputCell {...params} />,
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      editable: true,
      valueGetter: (params) => params[0],
      valueOptions: Array.from(new Set(rows.map((row: any) => row.campaign_name[0]))), // Dynamically fetch unique values
      type: 'singleSelect',
      renderCell: (params: GridRenderCellParams) => <CampaignCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <CampaignEditInputCell {...params} />,
      flex: 1,
    },
    {
      field: 'account',
      headerName: 'Account',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.account))), // Dynamically fetch unique values
      flex: 1,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <AccountEditInputCell {...params} profileKoBiddingOptionsRef={profileKoBiddingOptionsRef} />,
    },
    {
      field: 'ad_group_name',
      headerName: 'Ad Group',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.ad_group_name))), // Dynamically fetch unique values
      flex: 1,
      editable: true,
      renderCell: (params: any) => (
        <span>{params.value && params.value !== '' ? params.value : '-'}</span>
      ),
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <AdGroupEditInputCell {...params} adGroupOptionsRef={adGroupOptionsRef} />,
    },
    {
      field: 'mode',
      headerName: 'Mode',
      flex: 1,
      editable: true,
      valueOptions: Array.from(new Set(rows.map((row: any) => row.mode))), // Dynamically fetch unique values
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <ModeEditInputCell {...params} />,
    },
    {
      field: 'bidding_strategy_type',
      headerName: 'Bid Strategy Type',
      editable: false,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.bidding_strategy_type))), // Dynamically fetch unique values
      width: 200,
    },
    {
      field: 'country',
      headerName: 'Country',
      valueGetter: (params) => params[0],
      editable: false,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.country[0]))), // Dynamically fetch unique values
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const countriesList = Array.isArray(params.row.country)
          ? params.row.country.join(', ')
          : params.row.country;

        return (
          <Box display="flex" alignItems="center">
            {countriesList}
          </Box>
        );
      },
    },
    {
      field: 'target_roas',
      headerName: 'Target ROAS',
      editable: true,
      type: 'number',
      flex: 1,
      renderEditCell: (params: GridRenderCellParams) => <TargetROASEditInputCell {...params} />,
      renderCell: (params: GridRenderCellParams<any>) => `${params.value?.toFixed(2)}%`, // Ajout du signe pourcentage
    },
    {
      field: 'target_roas_variation',
      headerName: 'TROAS Variation',
      editable: true,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          {params.value ? (
            <Iconify icon={"material-symbols:check"} />
          ) : (
            <Iconify icon={"mdi:times"} />
          )}
        </Box>
      ),
      width: 170,
    },
    {
      field: 'max_cpc_portfolio_strategy',
      headerName: 'Max CPC',
      editable: true,
      type: 'number',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) =>
        params.value === null || params.value === 0 ? 'Not set' : `${params.value?.toFixed(2)}`,
      renderEditCell: (params: GridRenderCellParams) => <MaxCPCPortfolioStrategyEditInputCell {...params} />,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {formatUpdatedAt(params?.row?.created_at, currentLang?.value)}
        </span>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value ? `Active - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}` : `Inactive - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}`} arrow>
          <Box display="flex" alignItems="center" justifyContent="center">
            {params.value ? (
              <Iconify icon={"material-symbols:check"} />
            ) : (
              <Iconify icon={"mdi:times"} />
            )}
          </Box>
        </Tooltip>
      ),
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomKoBiddingTargetMaximize({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {

  const profileKoBiddingOptionsRef = useRef([]);
  const adGroupOptionsRef = useRef([]);

  const [rows, setRows]: any = useState<DataRow[]>(data);
  const [initialRows]: any = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const [pendingChanges, setPendingChanges] = useState<Map<number | string, Partial<DataRow>>>(new Map());
  const { currentLang, t } = useLocales();


  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [selectedCampaign, setSelectedCampaign]: any = useState<{ campaign_name: string, rowId: number | string } | null>(null);

  const [shoppingExist] = useCheckShoppingExistsMutation(); // Initialisez la mutation

  // États pour la confirmation
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [rowsToUpdate, setRowsToUpdate] = useState([]);
  const [resolveConfirm, setResolveConfirm]: any = useState(null);

  // Fonction de confirmation
  const confirmUpdate = (message: any, rows: any) => {
    setDialogMessage(message);
    setRowsToUpdate(rows);
    setIsDialogOpen(true);
    return new Promise((resolve) => {
      setResolveConfirm(() => resolve);
    });
  };

  // Handlers pour la modal
  const handleConfirm = () => {
    if (resolveConfirm) resolveConfirm(true);
    setIsDialogOpen(false);
    setRowsToUpdate([]);
  };

  const handleCancel = () => {
    if (resolveConfirm) resolveConfirm(false);
    setIsDialogOpen(false);
    setRowsToUpdate([]);
  };

  const workspace = useCurrentWorkspace()

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);
      const isModified = Object.keys(newRow).some(key => newRow[key] !== initialRow?.[key]);

      const accountChanged = newRow.account !== oldRow.account;
      const campaignChanged = JSON.stringify(newRow.campaign_name) !== JSON.stringify(oldRow.campaign_name);
      const adGroupChanged = newRow.ad_group_name !== oldRow.ad_group_name;

      let isDuplicate = false;
      let isDeleted = false;

      if (accountChanged) {
        newRow.country = [];
        newRow.country_code = [];
        newRow.ad_group_name = '';
        newRow.ad_group_id = '';

        const selectedAccount: any = profileKoBiddingOptionsRef.current.find(
          (opt: any) => opt.profile === newRow.account
        );
        newRow.account_id = selectedAccount ? selectedAccount.profile_id : '';
      }

      if (campaignChanged) {
        newRow.account = '';
        newRow.account_id = '';
        newRow.country = [];
        newRow.country_code = [];
        newRow.ad_group_name = '';
        newRow.ad_group_id = '';
        newRow.bidding_strategy_type = '';

        const selectedCampaign = initialRows.get(newRow.id)?.campaign_id;
        newRow.campaign_id = selectedCampaign ? [selectedCampaign] : [];
      }

      if (adGroupChanged) {
        const selectedAdGroup: any = adGroupOptionsRef.current.find(
          (opt: any) => opt.ad_group_name === newRow.ad_group_name
        );
        newRow.ad_group_id = selectedAdGroup ? selectedAdGroup.ad_group_id : '';
      }

      if (accountChanged || campaignChanged || adGroupChanged) {
        const allPendingChanges = Array.from(pendingChanges.values());
        const potentialDuplicates = allPendingChanges.filter(
          (row: any) =>
            row.id !== newRow.id &&
            row.account === newRow.account &&
            row.ad_group_name === newRow.ad_group_name &&
            JSON.stringify(row.campaign_name) === JSON.stringify(newRow.campaign_name)
        );

        if (potentialDuplicates.length > 0) {
          isDuplicate = true;
          isDeleted = true;
        } else {
          try {
            const response: any = await shoppingExist({
              campaign: newRow.campaign_name,
              profile: newRow.account,
              ad_group: newRow.ad_group_name,
              platform: newRow?.platform,
              workspaceId: workspace?.id,
            });
            isDuplicate = response?.error?.data?.exists || false;
            isDeleted = response?.error?.data?.exists || false;
          } catch (error) {
            console.warn("Erreur lors de la vérification de duplication :", error);
            isDuplicate = true;
            isDeleted = true;
          }
        }
      }

      // Gestion spécifique pour target_roas_portfolio_strategy
      const targetRoasChanged = newRow.target_roas !== oldRow.target_roas;
      if (targetRoasChanged) {
        // Copier la valeur de target_roas dans target_roas_portfolio_strategy
        newRow.target_roas_portfolio_strategy = newRow.target_roas;
      }

      const updatedRow = { ...newRow, isDuplicate, isDeleted };
      let newEditedRows = new Map(editedRows);
      if (isModified || isDuplicate) {
        newEditedRows.set(newRow.id, updatedRow);
      } else {
        newEditedRows.delete(newRow.id);
      }

      const maxCpcChanged = newRow.max_cpc_portfolio_strategy !== oldRow.max_cpc_portfolio_strategy;

      let rowsToUpdateArray: any[] = [];
      if ((maxCpcChanged || targetRoasChanged) && newRow.max_cpc_portfolio_strategy != null) {
        const newCampaignNames = Array.isArray(newRow.campaign_name)
          ? newRow.campaign_name
          : [newRow.campaign_name];

        // Mise à jour multiple : seules les lignes ayant la même campagne ET la même plateforme
        rowsToUpdateArray = rows.filter((row: any) => {
          const rowCampaignNames = Array.isArray(row.campaign_name)
            ? row.campaign_name
            : [row.campaign_name];
          return (
            newCampaignNames.some(campaignName => rowCampaignNames.includes(campaignName)) &&
            row.platform === newRow.platform && // Condition sur la plateforme
            row.id !== newRow.id &&
            (maxCpcChanged || (targetRoasChanged && row.target_roas != null))
          );
        });

        if (rowsToUpdateArray.length > 0) {
          const count = rowsToUpdateArray.length;
          const userConfirmed = await confirmUpdate(
            `En continuant, les paramètres actuels s'appliqueront à ${count} enregistrement${count > 1 ? 's' : ''} existant${count > 1 ? 's' : ''} associé${count > 1 ? 's' : ''} à cette campagne.`,
            rowsToUpdateArray
          );

          if (userConfirmed) {
            const updatedRowsWithChanges = rows.map((row: any) => {
              const shouldUpdate = rowsToUpdateArray.some(r => r.id === row.id);
              if (shouldUpdate) {
                return {
                  ...row,
                  max_cpc_portfolio_strategy: maxCpcChanged ? newRow.max_cpc_portfolio_strategy : row.max_cpc_portfolio_strategy,
                  target_roas: targetRoasChanged ? newRow.target_roas : row.target_roas,
                  target_roas_portfolio_strategy: newRow.target_roas, // Assurer la mise à jour
                };
              }
              return row;
            });

            setRows(updatedRowsWithChanges);

            rowsToUpdateArray.forEach(row => {
              newEditedRows.set(row.id, {
                ...row,
                max_cpc_portfolio_strategy: maxCpcChanged ? newRow.max_cpc_portfolio_strategy : row.max_cpc_portfolio_strategy,
                target_roas: targetRoasChanged ? newRow.target_roas : row.target_roas,
                target_roas_portfolio_strategy: newRow.target_roas,
              });
            });
          } else {
            return oldRow;
          }
        }
      }

      setRows((prevRows: any) => prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
      setEditedRows(newEditedRows);

      const editedRowsArray = Array.from(newEditedRows.entries()).map(([id, changes]) => ({
        id,
        changes,
      }));

      handleSave(editedRowsArray);
      onEdit(newEditedRows.size > 0);

      return updatedRow;
    },
    [
      rows,
      editedRows,
      initialRows,
      pendingChanges,
      profileKoBiddingOptionsRef,
      adGroupOptionsRef,
      shoppingExist,
      setRows,
      setEditedRows,
      handleSave,
      onEdit,
      confirmUpdate,
    ]
  );

  useEffect(() => {
    if (data) {
      setRows((prevRows: any) => {
        // Fusionner les nouvelles données avec l'état local
        const updatedRows = data.map((newRow: DataRow) => {
          const existingRow = prevRows.find((row: any) => row.id === newRow.id);

          if (existingRow) {
            // Conserver l'état local des lignes modifiées ou supprimées
            if (existingRow.isDeleted) {
              return { ...existingRow }; // Conserver toutes les valeurs modifiées de la ligne supprimée
            }
            if (editedRows.has(newRow.id)) {
              return { ...existingRow }; // Conserver toutes les modifications locales
            }
          }

          // Sinon, retourner la nouvelle ligne telle qu'elle est
          return newRow;
        });

        return updatedRows;
      });
    }
  }, [data, editedRows]);

  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row: any) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row: any) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows: any) => prevRows.map((row: any) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);

  const StyledGridOverlay = styled('div')(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .no-rows-primary': {
      fill: '#3D4751',
      ...theme.applyStyles('light', {
        fill: '#AEB8C2',
      }),
    },
    '& .no-rows-secondary': {
      fill: '#1D2126',
      ...theme.applyStyles('light', {
        fill: '#E8EAED',
      }),
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width={96}
          viewBox="0 0 452 257"
          aria-hidden
          focusable="false"
        >
          <path
            className="no-rows-primary"
            d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
          />
          <path
            className="no-rows-primary"
            d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
          />
          <path
            className="no-rows-primary"
            d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
          />
          <path
            className="no-rows-secondary"
            d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
          />
        </svg>
        <Box sx={{ mt: 2 }}>No rows</Box>
      </StyledGridOverlay>
    );
  }

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'country', "max_cpc_portfolio_strategy", "status", "match_type", "account", "campaign_name", "ad_group_name", "mode", "platform", "target_roas"].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };


  return (
    <Box
      sx={{
        height: rows?.length === 0 ? 400 : 'auto',

        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        isCellEditable={(params) => {
          if ((params.field === 'ad_group_name' && params.row.platform === 'Bing Ads')) {
            return false;
          }
          return true;
        }}
        disableRowSelectionOnClick
        rows={rows}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, profileKoBiddingOptionsRef, currentLang, rows, adGroupOptionsRef, editedRows)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: GridToolbar
        }}
      />

      {/* Modal de confirmation */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Configuration update warning</DialogTitle>
        <DialogContent>
          <Alert variant='outlined' severity={"warning"}>
            <AlertTitle sx={{ color: '#FFF5CC', position: 'relative', top: 2 }}>{dialogMessage}</AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
