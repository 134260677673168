import { useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import Iconify from 'src/components/iconify'
import { ComponentBlock } from 'src/sections/mui/component-block'

type StepOneContentProps = {
  selection: string
  setSelection: (value: string) => void
  subSelection: string
  setSubSelection: (value: string) => void
  koBiddingMode: string
  setKOBiddingMode: (value: string) => void
}

export function StepOneContent({
  selection,
  setSelection,
  subSelection,
  setSubSelection,
  koBiddingMode,
  setKOBiddingMode
}: StepOneContentProps) {
  const theme = useTheme()
  function buttonStyle(isSelected: boolean) {
    return {
      backgroundColor: isSelected ? '#7635dc' : '#1c252e',
      color:
        theme.palette.mode === 'dark'
          ? isSelected
            ? 'white'
            : 'white'
          : isSelected
            ? 'white'
            : 'black',
      border: `1px solid ${isSelected ? 'white' : '#2e3842'}`,
      width: '150px',
      height: '50px',
      margin: '10px'
    }
  }
  function handleSelection(value: string) {
    setSelection(value)
  }
  function handleSubSelection(value: string) {
    setSubSelection(value)
  }
  useEffect(() => {
    if (selection === 'Shopping') setSubSelection('Ko Bidding')
  }, [selection, setSubSelection])
  return (
    <Box>
      <ComponentBlock sx={{ marginTop: 7, width: '100%' }} title="Choose your campaign type">
        <Box display="flex" width="100%">
          <Button
            fullWidth
            sx={{ flex: 1, marginRight: 1, py: 6 }}
            style={buttonStyle(selection === 'Search')}
            onClick={() => handleSelection('Search')}
          >
            <Box display="flex" flexDirection="column" py={4}>
              <Box>
                <Iconify icon="solar:card-search-bold-duotone" width={30} />
                <Typography>Search</Typography>
              </Box>
            </Box>
          </Button>
          <Button
            fullWidth
            sx={{ flex: 1, marginRight: 1, py: 6 }}
            style={buttonStyle(selection === 'Shopping')}
            onClick={() => handleSelection('Shopping')}
          >
            <Box display="flex" flexDirection="column" py={4}>
              <Box>
                <Iconify icon="solar:tag-bold-duotone" width={30} />
                <Typography>Shopping</Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </ComponentBlock>
      <ComponentBlock sx={{ marginTop: 5, mb: 1, width: '100%' }} title="Choose your bidding strategy type">
        <Box display="flex" width="100%">
          <Button
            fullWidth
            sx={{ flex: 1, marginRight: 1, py: 6 }}
            style={buttonStyle(subSelection === 'Ko Bidding')}
            onClick={() => handleSubSelection('Ko Bidding')}
          >
            <Box display="flex" flexDirection="column" py={4}>
              <Box>
                <Iconify icon="solar:round-transfer-vertical-bold-duotone" width={30} />
                <Typography>KO Bidding</Typography>
              </Box>
            </Box>
          </Button>
          <Button
            fullWidth
            disabled={selection === 'Shopping'}
            sx={{
              flex: 1,
              marginRight: 1,
              py: 6,
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important',
                opacity: 0.4
              }
            }}
            style={buttonStyle(subSelection === 'Active Mode')}
            onClick={() => handleSubSelection('Active Mode')}
          >
            <Box display="flex" flexDirection="column" py={4}>
              <Box>
                <Iconify icon="solar:pause-circle-bold-duotone" width={30} />
                <Typography>Active Mode</Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </ComponentBlock>
      {selection === 'Search' && subSelection === 'Ko Bidding' && (
        <ComponentBlock sx={{ marginTop: 5, mb: 1, width: '100%' }} title="Choose your KO Bidding mode">
          <Box display="flex" width="100%">
            <Button
              fullWidth
              sx={{ flex: 1, marginRight: 1, py: 6 }}
              style={buttonStyle(koBiddingMode === 'Manual')}
              onClick={() => setKOBiddingMode('Manual')}
            >
              <Box display="flex" flexDirection="column" py={4}>
                <Box>
                  <Iconify icon="solar:round-transfer-vertical-bold-duotone" width={30} />
                  <Typography>Manual</Typography>
                </Box>
              </Box>
            </Button>
            <Button
              disabled
              fullWidth
              sx={{
                flex: 1,
                marginRight: 1,
                py: 6,
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important',
                  opacity: 0.4
                }
              }}
              style={buttonStyle(koBiddingMode === 'Auto')}
              onClick={() => setKOBiddingMode('Auto')}
            >
              <Box display="flex" flexDirection="column" py={4}>
                <Box>
                  <Iconify icon="solar:pause-circle-bold-duotone" width={30} />
                  <Typography>Auto</Typography>
                </Box>
              </Box>
            </Button>
          </Box>
        </ComponentBlock>
      )}
    </Box>
  )
}
