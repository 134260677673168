import { useEffect } from 'react'
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'

// Définissez (si vous le souhaitez) un type pour vos props
type StepTwoContentShoppingProps = {
  formDataShopping: any
  setFormDataShopping: (data: any) => void

  // Système de plateforme
  handlePlatformShoppingChange: (event: any, newValue: any) => void
  platformShoppingError: boolean

  // Campagnes
  campaignOptionsShopping: any[]
  isLoadingCampaignsShopping: boolean
  campaignShoppingError: boolean
  handleCampaignShoppingChange: (newValue: any) => void

  // Profil
  handleProfileShoppingChange: (newValue: any) => void
  isLoadingProfileShopping: boolean
  profileShoppingError: boolean
  profileOptionsShopping: any[]

  // AdGroups
  adGroupOptionsShopping: any[]
  isLoadingAdGroupsShopping: boolean
  handleAdGroupShoppingChange: (newValue: any) => void
  adGroupShoppingError: boolean

  // Bidding Strategy Type
  isLoadingBiddingStrategyType: boolean
  biddingStrategyType: any

  // Autres réglages
  targetRoasError: boolean
  handleTargetRoasChange: (value: string) => void
  handleMaxCPCPortfolioStartegy: (value: string) => void
}

export function StepTwoContentShopping({
  formDataShopping,
  setFormDataShopping,
  handleCampaignShoppingChange,
  handlePlatformShoppingChange,
  platformShoppingError,
  campaignOptionsShopping,
  isLoadingCampaignsShopping,
  campaignShoppingError,
  handleProfileShoppingChange,
  isLoadingBiddingStrategyType,
  biddingStrategyType,
  isLoadingProfileShopping,
  profileShoppingError,
  profileOptionsShopping,
  adGroupOptionsShopping,
  isLoadingAdGroupsShopping,
  handleAdGroupShoppingChange,
  adGroupShoppingError,
  targetRoasError,
  handleTargetRoasChange,
  handleMaxCPCPortfolioStartegy
}: StepTwoContentShoppingProps) {

  // Si vous souhaitez définir automatiquement l'account lorsque le tableau profileOptionsShopping comporte un seul profil
  useEffect(() => {
    if (profileOptionsShopping && profileOptionsShopping.length === 1) {
      setFormDataShopping({
        ...formDataShopping,
        account: profileOptionsShopping[0].profile
      })
    }
  }, [profileOptionsShopping, setFormDataShopping, formDataShopping])

  // Idem pour la/les campagne(s)
  useEffect(() => {
    if (campaignOptionsShopping && campaignOptionsShopping.length === 1) {
      setFormDataShopping((prev: any) => ({
        ...prev,
        campaign_name: campaignOptionsShopping[0].campaign_name
      }))
    }
  }, [campaignOptionsShopping, setFormDataShopping])

  // Idem pour l’ad group
  useEffect(() => {
    if (adGroupOptionsShopping && adGroupOptionsShopping.length === 1) {
      setFormDataShopping({
        ...formDataShopping,
        ad_group_name: adGroupOptionsShopping[0].ad_group_name,
        ad_group_id: adGroupOptionsShopping[0].ad_group_id
      })
    }
  }, [adGroupOptionsShopping, setFormDataShopping, formDataShopping])

  return (
    <Box sx={{ mb: 0.5 }}>

      {/* ----------- Platform ----------- */}
      <ComponentBlock sx={{ marginTop: 3 }} title="Platform">
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            disableClearable
            options={['Google Ads', 'Bing Ads']}
            value={formDataShopping.platform}
            onChange={handlePlatformShoppingChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Platform"
                placeholder="Select a platform"
                error={platformShoppingError}
                helperText={platformShoppingError ? 'At least one platform is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-disabled': {
                      border: platformShoppingError ? '1px solid red' : ''
                    },
                    '& fieldset': {
                      borderColor: platformShoppingError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
      </ComponentBlock>

      {/* ----------- Account ----------- */}
      {formDataShopping.platform && (
        <ComponentBlock sx={{ marginTop: 3 }} title="Account">
          <Box sx={{ width: '100%' }}>
            <Autocomplete
              disableClearable
              // Par exemple, vous pouvez désactiver si un seul profil
              disabled={!formDataShopping.platform || profileOptionsShopping.length === 1}
              options={isLoadingProfileShopping ? [] : profileOptionsShopping || []}
              value={
                profileOptionsShopping?.find(
                  (option) => option.profile === formDataShopping.account
                ) || null
              }
              // On délègue la mise à jour du formDataShopping.account
              // au parent via handleProfileShoppingChange.
              onChange={(event, newValue) => {
                handleProfileShoppingChange(newValue)
              }}
              getOptionLabel={(option) => option.profile || ''}
              isOptionEqualToValue={(option, value) => option.profile === value.profile}
              noOptionsText={isLoadingProfileShopping ? 'Loading...' : 'No options'}
              loading={isLoadingProfileShopping}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Account"
                  placeholder="Select an account"
                  error={profileShoppingError}
                  helperText={profileShoppingError ? 'At least one account is required' : ''}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-disabled': {
                        border: profileShoppingError ? '1px solid red' : ''
                      },
                      '& fieldset': {
                        borderColor: profileShoppingError ? 'red' : ''
                      }
                    }
                  }}
                />
              )}
            />
          </Box>
        </ComponentBlock>
      )}

      {/* ----------- Campaign ----------- */}
      {formDataShopping.account && formDataShopping.platform && (
        <ComponentBlock sx={{ marginTop: 3 }} title="Campaign">
          <Box sx={{ width: '100%' }}>
            <Autocomplete
              disableClearable
              loading={isLoadingCampaignsShopping}
              noOptionsText={campaignOptionsShopping ? 'Loading...' : 'No options'}
              disabled={
                !formDataShopping.account ||
                !formDataShopping.platform ||
                campaignOptionsShopping.length === 1
              }
              options={isLoadingCampaignsShopping ? [] : campaignOptionsShopping || []}
              getOptionLabel={(option) => option.campaign_name}
              value={
                campaignOptionsShopping?.find(
                  (option) => option.campaign_name === formDataShopping.campaign_name
                ) || null
              }
              onChange={(event, newValue) => handleCampaignShoppingChange(newValue)}
              isOptionEqualToValue={(option, value) =>
                option.campaign_name === value.campaign_name
              }
              renderTags={(value: any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip label={option.campaign_name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Campaign"
                  placeholder="Select a campaign"
                  error={campaignShoppingError}
                  helperText={campaignShoppingError ? 'Campaign is required' : ''}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-disabled': {
                        border: campaignShoppingError ? '1px solid red' : ''
                      },
                      '& fieldset': {
                        borderColor: campaignShoppingError ? 'red' : ''
                      }
                    }
                  }}
                />
              )}
            />
          </Box>
        </ComponentBlock>
      )}

      {/* ----------- Bidding Strategy Type ----------- */}
      {formDataShopping.account &&
        formDataShopping.platform &&
        formDataShopping.campaign_name?.length !== 0 && (
          <ComponentBlock sx={{ marginTop: 3 }} title="Bidding Strategy Type">
            <Box sx={{ width: '100%' }}>
              {isLoadingBiddingStrategyType ? (
                <TextField
                  sx={{ width: '100%' }}
                  value="Loading..."
                  variant="outlined"
                  label="Bidding Strategy Type"
                  disabled
                />
              ) : (
                <TextField
                  sx={{ width: '100%' }}
                  value={
                    formDataShopping.bidding_strategy_type ||
                    biddingStrategyType?.bidding_strategy_type ||
                    ''
                  }
                  variant="outlined"
                  label="Bidding Strategy Type"
                  InputProps={{
                    readOnly: true
                  }}
                  disabled
                />
              )}
            </Box>
          </ComponentBlock>
        )}

      {/* ----------- Ad Group (si Google Ads) ----------- */}
      {formDataShopping.platform !== 'Bing Ads' &&
        formDataShopping.account &&
        formDataShopping.platform &&
        formDataShopping.campaign_name && (
          <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group">
            <Box sx={{ width: '100%' }}>
              <Autocomplete
                disableClearable
                disabled={
                  formDataShopping.campaign_name?.length === 0 ||
                  formDataShopping.account?.length === 0 ||
                  adGroupOptionsShopping.length === 1
                }
                loading={isLoadingAdGroupsShopping}
                noOptionsText={adGroupOptionsShopping ? 'Loading...' : 'No options'}
                options={isLoadingAdGroupsShopping ? [] : adGroupOptionsShopping || []}
                getOptionLabel={(option) => option.ad_group_name || ''}
                value={
                  adGroupOptionsShopping?.find(
                    (option) => option.ad_group_id === formDataShopping.ad_group_id
                  ) || null
                }
                onChange={(event, newValue) => handleAdGroupShoppingChange(newValue)}
                isOptionEqualToValue={(option, value) => option.ad_group_id === value.ad_group_id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Ad Group"
                    placeholder="Select an ad group"
                    error={adGroupShoppingError}
                    helperText={adGroupShoppingError ? 'Ad Group is required' : ''}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-disabled': {
                          border: adGroupShoppingError ? '1px solid red' : ''
                        },
                        '& fieldset': {
                          borderColor: adGroupShoppingError ? 'red' : ''
                        }
                      }
                    }}
                  />
                )}
              />
            </Box>
          </ComponentBlock>
        )}

      {/* ----------- Target ROAS ----------- */}
      {formDataShopping.account &&
        formDataShopping.platform &&
        formDataShopping.campaign_name?.length !== 0 && (
          <ComponentBlock sx={{ marginTop: 3 }} title="Target ROAS (1 - 5000)">
            <Box sx={{ width: '100%' }}>
              <TextField
                variant="outlined"
                placeholder="Target ROAS"
                type="text"
                value={formDataShopping.target_roas}
                onChange={(event) => handleTargetRoasChange(event.target.value)}
                error={targetRoasError}
                helperText={targetRoasError ? 'La valeur doit être entre 1 et 5000' : ''}
                fullWidth
              />
            </Box>
          </ComponentBlock>
        )}

      {/* ----------- Advanced Settings (optionnel) ----------- */}
      {formDataShopping.account &&
        formDataShopping.platform &&
        formDataShopping.campaign_name?.length !== 0 && (
          <ComponentBlock sx={{ marginTop: 3 }} title="Advanced Settings (optional)">
            <Box sx={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(formDataShopping.targetRoasVariation)}
                    onChange={(e) =>
                      setFormDataShopping((prev: any) => ({
                        ...prev,
                        targetRoasVariation: e.target.checked
                      }))
                    }
                  />
                }
                label="Enable Target ROAS variation"
              />
            </Box>
            <ComponentBlock
              sx={{ marginTop: 3 }}
              title={`Max CPC (${
                formDataShopping.platform === 'Google Ads' ? '0.01' : '0.05'
              } - 50)`}
            >
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  placeholder="Max CPC"
                  type="text"
                  value={formDataShopping.max_cpc_portfolio_strategy ?? ''}
                  onChange={(event) => handleMaxCPCPortfolioStartegy(event.target.value)}
                  fullWidth
                  error={
                    parseFloat(formDataShopping.max_cpc_portfolio_strategy ?? '0') <
                      (formDataShopping.platform === 'Google Ads' ? 0.01 : 0.05) ||
                    parseFloat(formDataShopping.max_cpc_portfolio_strategy ?? '0') > 50
                  }
                  helperText={
                    parseFloat(formDataShopping.max_cpc_portfolio_strategy ?? '0') <
                      (formDataShopping.platform === 'Google Ads' ? 0.01 : 0.05) ||
                    parseFloat(formDataShopping.max_cpc_portfolio_strategy ?? '0') > 50
                      ? `Value must be between ${
                          formDataShopping.platform === 'Google Ads' ? '0.01' : '0.05'
                        } and 50`
                      : ''
                  }
                />
              </Box>
            </ComponentBlock>
          </ComponentBlock>
        )}
    </Box>
  )
}
