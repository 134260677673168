import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { fDate } from 'src/utils/format-time';
import { fPercent } from 'src/utils/format-number';

interface CustomLineChartProps {
    series: any;
    color?: string;
    height?: number;
    width?: number;
    formatValue?: (value: number) => string;
    shouldCapValues?: boolean;
    [key: string]: any;
}

const CustomLineChart: React.FC<CustomLineChartProps> = ({
    series,
    color = ' #2D0857',
    height = 30,
    width = 100,
    type = 'line',
    formatValue = fPercent,
    shouldCapValues = false,
    ...props
}) => {
    const theme = useTheme();
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (series && series.length > 0) {
            // Cap percentage values at 100% if needed
            const cappedValues = series.map((item: { date: string; value: number }) => ({
                ...item,
                value: item.value > 100 && shouldCapValues ? 100 : item.value
            }));

            // Process chart data with capped values
            // ...
        }
    }, [series, shouldCapValues]);

    const options = {
        
        chart: {
            sparkline: { enabled: true },
            animations: { enabled: false },
            toolbar: { show: false },
        },
        colors: [color],
        tooltip: {
            theme: theme.palette.mode === 'light' ? 'light' : 'dark',
            shared: true,
            followCursor: true,
            intersect: false,
            custom: ({ series, seriesIndex, dataPointIndex, w }: {
                series: number[][],
                seriesIndex: number,
                dataPointIndex: number,
                w: any
            }) => {
                const textColor = theme.palette.mode === 'light' ? '#212B36' : '#fff';
                const tooltipBgColor = theme.palette.mode === 'light' ? '#fff' : 'rgb(31, 38, 49)';
                const innerBgColor = theme.palette.mode === 'light' ? '#F4F6F8' : '#191f28';

                if (!series || !w.globals.seriesNames) return '';

                let formattedDate = '';
                try {
                    const dateStr = w.globals.seriesX[seriesIndex][dataPointIndex];
                    if (dateStr) {
                        const date = new Date(dateStr);
                        if (!isNaN(date.getTime())) {
                            formattedDate = fDate(date, 'yyyy-MM-dd');
                        }
                    }
                } catch (error) {
                    console.warn('Error formatting date:', error);
                }

                const value = series[seriesIndex][dataPointIndex];
                const formattedValue = formatValue(value);

                return `
                    <div style="z-index: 9999; border-radius: 8px; color: ${textColor}; line-height: 3.2; text-align: center; background-color: ${tooltipBgColor}; box-shadow: 0 2px 6px rgba(0,0,0,0.15);">
                        <div style="text-align: center; line-height: 2; margin-bottom: 1px">
                            <span style="font-weight: 600; font-size: 12px; font-family: Public Sans;">${formattedDate}</span>
                        </div>
                        <div style="background-color: ${innerBgColor}; display: flex; align-items: center; margin-bottom: 1px; padding: 2px 8px; border-radius:8px;">
                            <span style="display: inline-block; width: 16px; height: 2px; margin-right: 8px; background-color: ${color}; margin-top: 1px;"></span>
                            <span style="font-size: 12px;">${w.globals.seriesNames[seriesIndex]}:</span><span style="font-size: 12px; font-weight: bold; margin-left: 4px;">${formattedValue}</span>
                        </div>
                    </div>
                    `;
            },
            fixed: {
                enabled: false
            }
        },
        stroke: { width: 2, curve: 'smooth' as const },
        xaxis: {
            type: 'datetime' as const,
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false }
        },
        yaxis: { show: false },
        grid: { show: false,  },
        scales: {
            y: {
                min: 0,
                max: 100,  // Set maximum to 100 for percentage metrics
                display: false,
            },
            x: {
                display: false,
            },
        },
    };

    // Transform series data if needed
    const transformedSeries = Array.isArray(series) ? series : [series];

    return (
        <Chart
            type={type}
            series={transformedSeries}
            options={options}
            height={height}
            width={width}
            {...props}
        />
    );
};

CustomLineChart.defaultProps = {
    shouldCapValues: false,  // Set to true for percentage metrics
};

export default CustomLineChart;