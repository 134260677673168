import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCurrentUserQuery, useProviderSignInMutation, useProviderUserDeleteMutation, useSigninMutation } from 'src/context/api/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// auth

import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { useLocales } from 'src/locales';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { paths, ROOTS } from 'src/routes/paths';
import { enqueueSnackbar } from 'notistack';
import { useTheme } from '@mui/system';
import mixpanel from "mixpanel-browser";

export default function Signin() {
  const router = useRouter();
  const theme = useTheme()
  const [providerLoading, setProviderLoading] = useState(false);
  const [signin, response] = useSigninMutation();
  const password = useBoolean();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { t } = useLocales();
  const { search } = useLocation();
  const signinSchema = Yup.object().shape({
    email: Yup.string().required(t('fieldRequired', { field: "email" })),
    password: Yup.string().required(t('fieldRequired', { field: t("auth.form.signin.password") }))
  });
  const user = useCurrentUserQuery();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()
  const [providerSignIn,] = useProviderSignInMutation();
  const { loginWithPopup, isAuthenticated, user: auth0User, getAccessTokenSilently } = useAuth0();
  const [isLoadingSSO, setIsLoadingSSO] = useState(false);
  const [providerUserDelete] = useProviderUserDeleteMutation();

  const methods = useForm({
    resolver: yupResolver(signinSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (response.isError) {
      const error = response.error as any;
      const reason = error?.data.message || 'UnknownError';
      setErrorMsg(t('auth.' + reason));
      mixpanel.track('Signin Error', { reason });
    } else if (response.data) {
      // L’utilisateur est connecté
      mixpanel.track('Sign In Success', { email: methods.getValues('email') });
      
      user.refetch();
    }
  }, [response, user, methods]);
  

  useEffect(() => {
    if (user.data) {

      const role = user?.data?.role;
      const workspaces = user?.data?.workspaces || [];
      const firstWorkspace = workspaces?.at(0);

      if (role === "admin" && firstWorkspace) {
        router.push("/workspaces")
      } else if (role !== "admin" && workspaces && workspaces.length === 1) {
        router.push("/dash/brand-overview?workspace=" + workspaces.at(0).id)
      } else if (role !== "admin" && workspaces && workspaces.length > 1) {
        router.push("/workspaces")
      }
    }

  }, [user.data, dispatch, response.data, setSearchParams, router]);

  const signInWithProvider = async (provider: any) => {
    setProviderLoading(true);
    try {
      const result = await loginWithPopup({ authorizationParams: { connection: provider } })
      const token = await getAccessTokenSilently();
      const response: any = await providerSignIn({ token, provider });

      if (response.data.user) {

        router.push('/auth/sso_loader')
      }
      else {
        enqueueSnackbar('Error: User login failed', { variant: 'error' });
      }

    } catch (error) {
      enqueueSnackbar('Authentification Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });

      if (isAuthenticated) {
        const idUser: any = auth0User?.sub
        const newToken: any = getAccessTokenSilently()
        await providerUserDelete({ token: newToken, userId: idUser }).unwrap();
      }
    } finally {
      setProviderLoading(false);
    }
  }


  return (
    <div style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: 'url(/assets/background/background.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>

      <div style={{
        width: '100%',
        maxWidth: '400px',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '40px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
          <div style={{
            width: '100px',
            height: '100px',

            position: 'relative',
            borderRadius: '50%',
            overflow: 'hidden',
          }}>
            <div style={{
              width: '100%',
              height: '100%',
              backgroundImage: 'linear-gradient(180deg, rgba(0, 167, 111, 1) -100%, rgba(0, 167, 111, 0.22) 100%)',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              opacity: 0.3,
              borderRadius: '50%',
            }}>

            </div>
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
            }}>

              <img
                src='/assets/logo.png'
                alt="Logo"
                style={{
                  width: '60px',
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </div>
        <Box sx={{ display: isLoadingSSO ? 'none' : 'block' }}>
          <FormProvider methods={methods} onSubmit={methods.handleSubmit(signin)}>
            <Stack spacing={2} sx={{ mb: 5 }}>
              <Typography style={{ color: '#1C252E', textAlign: 'center' }} variant="h4">
                {t('auth.form.signin.signinTo')}
              </Typography>
            </Stack>
            <Stack spacing={2.5}>
              {!!errorMsg && <Alert sx={{ color: "white" }} severity="error">{errorMsg}</Alert>}
              <RHFTextField
                shrink
                name="email"
                label={t('auth.form.signin.Email')}
                InputLabelProps={{
                  sx: {
                    color: '#1C252E',
                    '&.Mui-focused': {
                      color: '#1C252E !important',
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    color: '#1C252E',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#637381',
                    },
                    '&:hover fieldset': {
                      borderColor: '#637381',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4c5b6e',
                    },
                    '& input:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px white inset',
                      '-webkit-text-fill-color': '#1C252E',
                    },
                  },
                }}
              />

              <RHFTextField
                name="password"
                label={t('auth.form.signin.password')}
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    color: '#1C252E'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: '#1C252E',
                    '&.Mui-focused': {
                      color: '#1C252E !important',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#637381',
                    },
                    '&:hover fieldset': {
                      borderColor: '#637381',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4c5b6e',
                    },
                    '& input:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px white inset',
                      '-webkit-text-fill-color': '#1C252E',
                    },
                  },
                }}
              />

              <Link
                component={RouterLink}
                href={'/auth/forgot'}
                variant="body2"
                color="inherit"
                underline="always"
                sx={{
                  alignSelf: 'flex-end', color: '#1C252E', textDecoration: 'none', '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {t('auth.form.signin.ForgotPassword?')}
              </Link>

              <LoadingButton
                fullWidth
                sx={{ backgroundColor: '#1C252E', color: 'white' }}
                size="large"
                type="submit"
                variant="contained"
                loading={methods.formState.isSubmitting}
              >
                {t('auth.form.signin.Login')}
              </LoadingButton>


              <Divider><Typography color='black' variant='subtitle1'> or</Typography></Divider>
              <Button
                variant='outlined'
                sx={{
                  height: '50px',
                  border: 'solid 1px black',
                  borderRadius: '8px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 5,
                  transition: 'background-color 0.3s, border-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                }}
                onClick={() => signInWithProvider('google-oauth2')}
              >
                <Iconify icon="logos:google-icon" width={24} />
                <Typography variant="subtitle1" color='black'>Sign in with Google</Typography>
              </Button>
              {/* <Button
                  variant='outlined'
                  sx={{
                    height: '50px',
                    border: 'solid 1px black',
                    borderRadius: '8px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 5,
                    transition: 'background-color 0.3s, border-color 0.3s',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => signInWithProvider('windowslive')}
                >
                  <Iconify icon="logos:microsoft-icon" width={24} />
                  <Typography variant="subtitle1" color='black'>Sign in with Microsoft</Typography>
                </Button> */}
            </Stack>
          </FormProvider>
        </Box>
      </div>
    </div>
  );
}
