import { Box, Alert, Typography } from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom'

type StepThreeContentKoBiddingProps = {
  formDataKoBidding: any
  searchKoBiddingDuplicate: any
  shoppingTargetROASDuplicate: any
  shoppingMaxCPCDuplicate: any
  shoppingDuplicate: any
}


export function StepThreeContentKoBidding({
  formDataKoBidding,
  searchKoBiddingDuplicate,
  shoppingTargetROASDuplicate,
  shoppingMaxCPCDuplicate
}: StepThreeContentKoBiddingProps) {
  console.log(formDataKoBidding, "ijfzifezijf")
  return (
    <Box sx={{ mt: 4 }}>

      {searchKoBiddingDuplicate && (
        <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
          <Typography sx={{ fontWeight: 900 }}>{searchKoBiddingDuplicate.title}</Typography>
          <Typography fontSize={14} sx={{ mt: 0.4 }}>
            {searchKoBiddingDuplicate.description}
          </Typography>
        </Alert>
      )}

      <ComponentBlock sx={{ marginTop: 2 }} title="Review Your KO Bidding Strategy">
        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Client">
          <Typography variant="body1">{formDataKoBidding.client}</Typography>
        </ComponentBlockCustom>

        {!formDataKoBidding.target_roas && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Platform">
            <Typography variant="body1">{formDataKoBidding.platform}</Typography>
          </ComponentBlockCustom>
        )}

        {!formDataKoBidding.target_roas && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Keyword">
            <Typography variant="body1">{formDataKoBidding.keyword_bid}</Typography>
          </ComponentBlockCustom>
        )}

        {!formDataKoBidding.target_roas && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Match Type">
            <Typography variant="body1">{formDataKoBidding.match_type}</Typography>
          </ComponentBlockCustom>
        )}

        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Account">
          <Typography variant="body1">{formDataKoBidding.account}</Typography>
        </ComponentBlockCustom>

        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign Name">
          <Typography variant="body1">{formDataKoBidding.campaign_name}</Typography>
        </ComponentBlockCustom>

        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Ad Group">
          <Typography variant="body1">{formDataKoBidding.ad_group_name}</Typography>
        </ComponentBlockCustom>

        {formDataKoBidding.target_roas && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Target ROAS">
            {shoppingTargetROASDuplicate ? (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'line-through',
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                    padding: '4px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    mr: 1
                  }}
                >
                  {formDataKoBidding.target_roas}
                </Typography>
                <Typography variant="body1" sx={{ display: 'inline' }}>
                  {shoppingTargetROASDuplicate.target_roas * 100}
                </Typography>
                <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                  <Typography sx={{ fontWeight: 900 }}>{shoppingTargetROASDuplicate.title}</Typography>
                  <Typography fontSize={14} sx={{ mt: 0.4 }}>
                    {shoppingTargetROASDuplicate.description}
                  </Typography>
                </Alert>
              </Box>
            ) : (
              <Typography variant="body1">{formDataKoBidding.target_roas}</Typography>
            )}
          </ComponentBlockCustom>
        )}

        {formDataKoBidding.max_cpc_daily_reduction && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Max CPC Daily Reduction">
            <Typography variant="body1">{formDataKoBidding.max_cpc_daily_reduction}</Typography>
          </ComponentBlockCustom>
        )}

        {!["KO Bidding Blended CTR", "KO Bidding Ad CTR"].includes(formDataKoBidding.mode) &&
          !formDataKoBidding.target_roas && (
            <ComponentBlockCustom sx={{ marginTop: 2 }} title="Max CPC">
              <Typography variant="body1">{formDataKoBidding.max_cpc}</Typography>
            </ComponentBlockCustom>
          )
        }

        {formDataKoBidding.max_cpc_portfolio_strategy && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
            {shoppingMaxCPCDuplicate ? (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'line-through',
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                    padding: '4px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    mr: 1
                  }}
                >
                  {formDataKoBidding.max_cpc_portfolio_strategy}
                </Typography>
                <Typography variant="body1" sx={{ display: 'inline' }}>
                  {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                </Typography>
                <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                  <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate.title}</Typography>
                  <Typography fontSize={14} sx={{ mt: 0.4 }}>
                    {shoppingMaxCPCDuplicate.description}
                  </Typography>
                </Alert>
              </Box>
            ) : (
              <Typography variant="body1">{formDataKoBidding.max_cpc_portfolio_strategy}</Typography>
            )}
          </ComponentBlockCustom>
        )}

        {!formDataKoBidding.max_cpc_portfolio_strategy && shoppingMaxCPCDuplicate && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
            <Typography variant="body1" sx={{ display: 'inline' }}>
              {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
            </Typography>
            <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
              <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate.title}</Typography>
              <Typography fontSize={14} sx={{ mt: 0.4 }}>
                {shoppingMaxCPCDuplicate.description}
              </Typography>
            </Alert>
          </ComponentBlockCustom>
        )}

      </ComponentBlock>
    </Box>
  )
}
