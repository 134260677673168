import { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel,
  Typography,
  InputAdornment
} from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'
import Iconify from 'src/components/iconify'

type StepTwoContentKoBiddingProps = {
  // Toutes les props dont vous avez besoin :
  keywordKoBiddingAutoOptions: any[]
  adGroupOptionsKoBiddingAuto: any[]
  workspace: any
  subSelection: string
  selection: string
  koBiddingMode: string
  handleTargetImpressionShareChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleTargetImpressionSharePositionChange: (newValue: string | null) => void
  handleDecimalPortFolioStratChange: (value: string) => void
  setErrorTargetCPA: (value: boolean) => void
  errorTargetCPA: boolean
  setFormDataKoBidding: (data: any) => void
  adGroupOptionsKoBiddingTargetMaximize: any[]
  handleMaxCPCMaximizePortfolioStartegy: (value: string) => void
  targetRoasError: boolean
  handleTargetRoasMaximizeChange: (value: string) => void
  formDataKoBidding: any
  accountIdSa360Error: boolean
  handleAccountIdSa360Change: (value: any) => void
  modeKoBiddingError: boolean
  adGroupKoBiddingError: boolean
  keywordKoBiddingError: boolean
  platformError: boolean
  campaignKoBiddingError: boolean
  profileError: boolean
  matchTypeKoBiddingErrorState: boolean
  handleAdGroupKoBiddingChange: (newValue: any) => void
  handleDecimalChange: (value: string) => void
  adGroupOptionsKoBidding: any[]
  isLoadingAdGroupKoBidding: boolean
  isLoadingCampaignsKoBidding: boolean
  campaignOptionsKoBidding: any[]
  handleCampaignKoBiddingChange: (newValue: any) => void
  profileKoBiddingOptions: any[]
  isLoadingProfileKoBidding: boolean
  handleAccountKoBiddingChange: (event: any, newValue: any) => void
  handleModeKoBiddingChange: (event: any, newValue: any) => void
  handleMatchTypeChange: (event: any, newValue: any) => void
  isLoadingMatchTypeKoBidding: boolean
  matchTypeKoBiddingOptions: any[]
  handlePlatformChange: (event: any, newValue: any) => void
  handleKeywordKoBiddingChange: (event: any, newValue: any) => void
  keywordKoBiddingOptions: any[]
  isLoadingKoBiddingKeywords: boolean
}

export function StepTwoContentKoBidding({
  // Liste des props
  keywordKoBiddingAutoOptions,
  adGroupOptionsKoBiddingAuto,
  workspace,
  subSelection,
  selection,
  koBiddingMode,
  handleTargetImpressionShareChange,
  handleTargetImpressionSharePositionChange,
  handleDecimalPortFolioStratChange,
  setErrorTargetCPA,
  errorTargetCPA,
  setFormDataKoBidding,
  adGroupOptionsKoBiddingTargetMaximize,
  handleMaxCPCMaximizePortfolioStartegy,
  targetRoasError,
  handleTargetRoasMaximizeChange,
  formDataKoBidding,
  accountIdSa360Error,
  handleAccountIdSa360Change,
  modeKoBiddingError,
  adGroupKoBiddingError,
  keywordKoBiddingError,
  platformError,
  campaignKoBiddingError,
  profileError,
  matchTypeKoBiddingErrorState,
  handleAdGroupKoBiddingChange,
  handleDecimalChange,
  adGroupOptionsKoBidding,
  isLoadingAdGroupKoBidding,
  isLoadingCampaignsKoBidding,
  campaignOptionsKoBidding,
  handleCampaignKoBiddingChange,
  profileKoBiddingOptions,
  isLoadingProfileKoBidding,
  handleAccountKoBiddingChange,
  handleModeKoBiddingChange,
  handleMatchTypeChange,
  isLoadingMatchTypeKoBidding,
  matchTypeKoBiddingOptions,
  handlePlatformChange,
  handleKeywordKoBiddingChange,
  keywordKoBiddingOptions,
  isLoadingKoBiddingKeywords
}: StepTwoContentKoBiddingProps) {
  // Gère l'agressivité en mode Auto
  const [aggressivity, setAggressivity] = useState<string>('Low')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorAcceptedVariation, setErrorAcceptedVariation] = useState('')

  // ------------------- Fonctions de calcul -------------------
  function computeTargetMaxCpc() {
    const initial = parseFloat(formDataKoBidding.initial_max_cpc) || 0
    const reductionPct = parseFloat(formDataKoBidding.max_cpc_target_reduction) || 0
    const target = (reductionPct / 100) * initial
    if (isNaN(target)) return ''
    return target.toFixed(2)
  }

  function computeMaxCpcDailyReduction() {
    const initial = parseFloat(formDataKoBidding.initial_max_cpc) || 0
    const reductionPct = parseFloat(formDataKoBidding.max_cpc_target_reduction) || 0
    const duration = parseFloat(formDataKoBidding.optimization_phase_duration) || 0
    if (duration <= 0) return ''
    const daily = (reductionPct / 100 / duration) * initial
    if (isNaN(daily)) return ''
    return daily.toFixed(2)
  }

  function isTargetMaxCpcOutOfRange() {
    const val = parseFloat(computeTargetMaxCpc())
    return val < 0.01 || val > 50
  }

  function isDailyReductionOutOfRange() {
    const val = parseFloat(computeMaxCpcDailyReduction())
    return val < 0.01 || val > 50
  }

  const isMaxCpcReductionMsgVisible = isTargetMaxCpcOutOfRange()
  const isDailyReductionMsgVisible = isDailyReductionOutOfRange()
  const targetMaxCpcValue = parseFloat(computeTargetMaxCpc())
  const isError = targetMaxCpcValue < 0.01 || targetMaxCpcValue > 50

  // ------------------- Handle Target CPA -------------------
  function handleTargetCPAChange(event: any) {
    const { value } = event.target
    setFormDataKoBidding((prev: any) => ({
      ...prev,
      target_cpa: value
    }))
    if (value) {
      setErrorTargetCPA(false)
    }
  }

  // ------------------- Handle max_cpc_target_reduction (%) -------------------
  function handleMaxCpcChange(e: any) {
    const valueStr = e.target.value
    const valueNum = parseInt(valueStr, 10)
    let msg = ''

    // Permettre à l’utilisateur d’effacer le champ
    if (valueStr === '') {
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        max_cpc_target_reduction: ''
      }))
      setErrorMessage('')
      return
    }
    if (isNaN(valueNum)) {
      msg = 'Veuillez saisir un nombre valide.'
    } else if (valueNum < 0) {
      msg = 'La valeur ne peut pas être négative.'
    } else if (valueNum > 100) {
      msg = 'La valeur ne peut pas dépasser 100%.'
    }
    setErrorMessage(msg)
    setFormDataKoBidding((prev: any) => ({
      ...prev,
      max_cpc_target_reduction: valueStr
    }))
  }

  // ------------------- useEffect(s) pour le mode Auto -------------------

  // 1) Forcer certains champs si koBiddingMode === "Auto"
  useEffect(() => {
    if (selection === 'Search' && subSelection === 'Ko Bidding' && koBiddingMode === 'Auto') {
      setFormDataKoBidding((prev: any) => {
        const newType = prev.type || 'Blended'
        const newPlatform = 'Google Ads'
        let newMode = prev.mode
        if (newType === 'Blended') {
          newMode = 'KO Bidding Blended CTR'
        } else if (newType === 'Ad') {
          newMode = 'KO Bidding Ad CTR'
        }
        return {
          ...prev,
          type: newType,
          platform: newPlatform,
          mode: newMode,
          match_type: prev.match_type || 'Exact',
          status: false
        }
      })
    }
  }, [koBiddingMode, selection, subSelection, setFormDataKoBidding])

  // 2) Auto-remplir match_type=Exact si vide (mode Auto)
  useEffect(() => {
    if (
      koBiddingMode === 'Auto' &&
      (!formDataKoBidding.match_type || formDataKoBidding.match_type.trim() === '')
    ) {
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        match_type: 'Exact'
      }))
    }
  }, [koBiddingMode, formDataKoBidding.match_type, setFormDataKoBidding])

  // 3) Calcul target_max_cpc / max_cpc_daily_reduction
  useEffect(() => {
    if (
      formDataKoBidding.initial_max_cpc &&
      formDataKoBidding.max_cpc_target_reduction &&
      formDataKoBidding.optimization_phase_duration
    ) {
      const initial = parseFloat(formDataKoBidding.initial_max_cpc) || 0
      const reductionPct = parseFloat(formDataKoBidding.max_cpc_target_reduction) || 0
      const duration = parseFloat(formDataKoBidding.optimization_phase_duration) || 0
      const computedTargetMaxCpc = (reductionPct / 100) * initial
      const computedDailyReduction =
        duration > 0 ? (reductionPct / 100 / duration) * initial : 0
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        target_max_cpc: computedTargetMaxCpc.toFixed(2),
        max_cpc_daily_reduction: computedDailyReduction.toFixed(2)
      }))
    }
  }, [
    formDataKoBidding.initial_max_cpc,
    formDataKoBidding.max_cpc_target_reduction,
    formDataKoBidding.optimization_phase_duration,
    setFormDataKoBidding
  ])

  // 4) Si on n’est pas en mode Auto et qu’on est sur des Bidding Strategies type ... on force mode=KO Bidding Manual
  useEffect(() => {
    if (
      koBiddingMode !== 'Auto' &&
      ['Target CPA', 'Maximize Conversions', 'Target Impression Share', 'Max CPC', 'Target ROAS', 'Maximize Conversion Value'].includes(
        formDataKoBidding.bidding_strategy_type
      )
    ) {
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        mode: 'KO Bidding Manual'
      }))
    }
  }, [koBiddingMode, formDataKoBidding.bidding_strategy_type, formDataKoBidding.platform, setFormDataKoBidding])

  useEffect(() => {
    if (koBiddingMode !== 'Auto') return
  
    // Si "Custom", on vide les champs
    if (aggressivity === 'Custom') {
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        optimization_phase_duration: '',
        max_cpc_target_reduction: '',
        accepted_variation_threshold: ''
      }))
      return
    }
  
    const initial = parseFloat(formDataKoBidding.initial_max_cpc) || 0
    let optimization_phase_duration: number
    let max_cpc_target_reduction: number
    let accepted_variation_threshold: number
  
    if (aggressivity === 'Low') {
      if (initial >= 0.3) {
        optimization_phase_duration = 30
        let reduction = (0.01 * 30) / initial
        if (reduction < 0.01) reduction = 0.01
        max_cpc_target_reduction = reduction * 100
      } else {
        optimization_phase_duration = initial * 100
        max_cpc_target_reduction = 100
      }
      accepted_variation_threshold = 2
    } else if (aggressivity === 'Medium') {
      if (initial >= 0.7) {
        optimization_phase_duration = 30
        max_cpc_target_reduction = 25
      } else {
        optimization_phase_duration = initial * 100
        max_cpc_target_reduction = 100
      }
      accepted_variation_threshold = 2
    } else if (aggressivity === 'High') {
      if (initial >= 0.5) {
        optimization_phase_duration = 30
        max_cpc_target_reduction = 50
      } else {
        optimization_phase_duration = initial * 100
        max_cpc_target_reduction = 100
      }
      accepted_variation_threshold = 1
    }
  
    setFormDataKoBidding((prev: any) => ({
      ...prev,
      optimization_phase_duration,
      max_cpc_target_reduction,
      accepted_variation_threshold
    }))
  }, [aggressivity, koBiddingMode, formDataKoBidding.initial_max_cpc, setFormDataKoBidding])
  

  // 6) accepted_variation_threshold par défaut = 2 en Auto
  useEffect(() => {
    if (
      koBiddingMode === 'Auto' &&
      (formDataKoBidding.accepted_variation_threshold === undefined ||
        formDataKoBidding.accepted_variation_threshold === null)
    ) {
      setFormDataKoBidding((prev: any) => ({
        ...prev,
        accepted_variation_threshold: 2
      }))
    }
  }, [koBiddingMode, formDataKoBidding.accepted_variation_threshold, setFormDataKoBidding])

  // ------------------- Rendu -------------------
  return (
    <>
      {/* -------------------------------------------------------------------------- */}
      {/* MODE MANUAL                                                                */}
      {/* -------------------------------------------------------------------------- */}
      {selection === 'Search' && subSelection === 'Ko Bidding' && koBiddingMode === 'Manual' && (
        <>
          {/* PLATFORM */}
          <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Platform">
            <Box sx={{ width: '100%' }}>
              <Autocomplete
                disableClearable
                options={['Google Ads', 'Bing Ads', 'SA360']}
                value={formDataKoBidding.platform}
                onChange={handlePlatformChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Platform"
                    placeholder="Select a platform"
                    error={platformError}
                    helperText={platformError ? 'At least one platform is required' : ''}
                  />
                )}
              />
            </Box>
          </ComponentBlock>

          {/* SA360 Account ID */}
          {formDataKoBidding.platform === 'SA360' && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="SA360 Account ID">
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  label="SA360 Account ID"
                  placeholder="SA360 Account ID"
                  value={formDataKoBidding.account_id_sa360 || ''}
                  onChange={(event) => handleAccountIdSa360Change(event.target.value)}
                  error={accountIdSa360Error}
                  helperText={accountIdSa360Error ? 'SA360 Account ID is required' : ''}
                  fullWidth
                />
              </Box>
            </ComponentBlock>
          )}

          {/* ACCOUNT */}
          {formDataKoBidding.platform && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Account">
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  disableClearable
                  disabled={!formDataKoBidding.platform}
                  options={isLoadingProfileKoBidding ? [] : profileKoBiddingOptions || []}
                  value={
                    profileKoBiddingOptions.find(
                      (option: any) => option.profile === formDataKoBidding.account
                    ) || null
                  }
                  onChange={handleAccountKoBiddingChange}
                  getOptionLabel={(option: any) => option.profile}
                  noOptionsText={isLoadingProfileKoBidding ? 'Loading...' : 'No options'}
                  loading={isLoadingProfileKoBidding}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Account"
                      placeholder="Select an account"
                      error={profileError}
                      helperText={profileError ? 'At least one account is required' : ''}
                    />
                  )}
                />
              </Box>
            </ComponentBlock>
          )}

          {/* CAMPAIGN */}
          {formDataKoBidding.account && formDataKoBidding.platform && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Campaign">
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  multiple={false}
                  disableClearable
                  loading={isLoadingCampaignsKoBidding}
                  noOptionsText={campaignOptionsKoBidding ? 'Loading...' : 'No options'}
                  options={isLoadingCampaignsKoBidding ? [] : campaignOptionsKoBidding || []}
                  getOptionLabel={(option: any) => option.campaign_name || ''}
                  value={
                    campaignOptionsKoBidding.find(
                      (opt: any) => opt.campaign_name === formDataKoBidding.campaign_name
                    ) || null
                  }
                  onChange={(event, newValue) => handleCampaignKoBiddingChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Campaign"
                      placeholder="Select a campaign"
                      error={campaignKoBiddingError}
                      helperText={campaignKoBiddingError ? 'Campaign is required' : ''}
                    />
                  )}
                />
              </Box>
            </ComponentBlock>
          )}

          {/* BIDDING STRATEGY TYPE (read only) */}
          {formDataKoBidding.bidding_strategy_type && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Bidding Strategy Type">
              <Box sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={formDataKoBidding.bidding_strategy_type}
                  variant="outlined"
                  label="Bidding Strategy Type"
                  disabled
                />
              </Box>
            </ComponentBlock>
          )}

          {/* CAS "Max CPC" */}
          {formDataKoBidding.bidding_strategy_type === 'Max CPC' && (
            <>
              {/* Keyword */}
              <ComponentBlock sx={{ marginTop: 3 }} title="Keyword">
                <Box sx={{ width: '100%' }}>
                  <Autocomplete
                    disableClearable
                    options={isLoadingKoBiddingKeywords ? [] : keywordKoBiddingOptions || []}
                    getOptionLabel={(option: any) => option.keyword_bid || ''}
                    value={
                      keywordKoBiddingOptions.find(
                        (opt: any) => opt.keyword_bid === formDataKoBidding.keyword_bid
                      ) || null
                    }
                    onChange={handleKeywordKoBiddingChange}
                    noOptionsText={isLoadingKoBiddingKeywords ? 'Loading...' : 'No options'}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Keyword"
                        placeholder="Select a keyword"
                        error={keywordKoBiddingError}
                        helperText={keywordKoBiddingError ? 'At least one keyword is required' : ''}
                      />
                    )}
                  />
                </Box>
              </ComponentBlock>

              {/* Match Type */}
              {formDataKoBidding.keyword_bid && (
                <ComponentBlock sx={{ marginTop: 3 }} title="Match Type">
                  <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      disableClearable
                      options={isLoadingMatchTypeKoBidding ? [] : matchTypeKoBiddingOptions || []}
                      value={formDataKoBidding.match_type}
                      onChange={handleMatchTypeChange}
                      noOptionsText="No options"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Match Type"
                          placeholder="Select a match type"
                          error={matchTypeKoBiddingErrorState}
                          helperText={
                            matchTypeKoBiddingErrorState ? 'At least one match type is required' : ''
                          }
                        />
                      )}
                    />
                  </Box>
                </ComponentBlock>
              )}

              {/* Ad Group */}
              {formDataKoBidding.keyword_bid &&
                formDataKoBidding.account &&
                formDataKoBidding.campaign_name &&
                formDataKoBidding?.match_type && (
                  <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group">
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        disableClearable
                        options={isLoadingAdGroupKoBidding ? [] : adGroupOptionsKoBidding || []}
                        getOptionLabel={(option: any) => option.ad_group || ''}
                        value={
                          adGroupOptionsKoBidding.find(
                            (opt: any) => opt.ad_group_id === formDataKoBidding.ad_group_id
                          ) || null
                        }
                        onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Ad Group"
                            placeholder="Select an ad group"
                            error={adGroupKoBiddingError}
                            helperText={
                              adGroupKoBiddingError ? 'At least one ad group is required' : ''
                            }
                          />
                        )}
                      />
                    </Box>
                  </ComponentBlock>
                )}

              {/* Max CPC */}
              {formDataKoBidding.keyword_bid &&
                formDataKoBidding.account &&
                formDataKoBidding.campaign_name &&
                formDataKoBidding.ad_group_name &&
                formDataKoBidding.mode && (
                  <ComponentBlock
                    sx={{ marginTop: 3, mb: 1 }}
                    title={`Max CPC (${formDataKoBidding.platform === 'Google Ads' ? '0.01' : '0.05'
                      } - 50)`}
                  >
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        variant="outlined"
                        placeholder="Max CPC"
                        type="text"
                        value={formDataKoBidding.max_cpc}
                        onChange={(event) => handleDecimalChange(event.target.value)}
                        error={
                          parseFloat(formDataKoBidding.max_cpc) <
                          (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                          parseFloat(formDataKoBidding.max_cpc) > 50
                        }
                        helperText={
                          parseFloat(formDataKoBidding.max_cpc) <
                            (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                            parseFloat(formDataKoBidding.max_cpc) > 50
                            ? `Value must be between ${formDataKoBidding.platform === 'Google Ads' ? '0.01' : '0.05'
                            } and 50`
                            : ''
                        }
                        fullWidth
                      />
                    </Box>
                  </ComponentBlock>
                )}
            </>
          )}

          {/* Target Impression Share */}
          {['Target Impression Share'].includes(formDataKoBidding.bidding_strategy_type) && (
            <>
              <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Ad Placement">
                <Box sx={{ width: '100%' }}>
                  <Autocomplete
                    disableClearable
                    options={[
                      'Anywhere on results page',
                      'Top of results page',
                      'Absolute top of results page'
                    ]}
                    value={formDataKoBidding.target_impression_share_position || ''}
                    onChange={(event, newValue) => {
                      handleTargetImpressionSharePositionChange(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Where do you want your ads to appear?"
                        placeholder="Select a position"
                      />
                    )}
                  />
                </Box>
              </ComponentBlock>

              <ComponentBlock sx={{ marginTop: 3 }} title="Target Impression Share (0% - 100%)">
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Target Impression Share"
                    type="number"
                    value={formDataKoBidding.target_impression_share || ''}
                    onChange={handleTargetImpressionShareChange}
                    fullWidth
                    error={
                      parseFloat(formDataKoBidding.target_impression_share) < 0 ||
                      parseFloat(formDataKoBidding.target_impression_share) > 100
                    }
                    helperText={
                      parseFloat(formDataKoBidding.target_impression_share) < 0 ||
                        parseFloat(formDataKoBidding.target_impression_share) > 100
                        ? 'Value must be between 0 and 100'
                        : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              backgroundColor: '#37424d',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '2px 10px 2px 10px',
                              borderRadius: '3px'
                            }}
                          >
                            <Typography sx={{ color: 'white', fontWeight: 600 }}>%</Typography>
                          </Box>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </ComponentBlock>

              <ComponentBlock
                sx={{ marginTop: 3 }}
                title={`Max CPC ${formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05
                  } - 50`}
              >
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Max CPC"
                    type="text"
                    value={formDataKoBidding.max_cpc}
                    onChange={(event) => handleDecimalChange(event.target.value)}
                    fullWidth
                    error={
                      parseFloat(formDataKoBidding.max_cpc) <
                      (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                      parseFloat(formDataKoBidding.max_cpc) > 50
                    }
                    helperText={
                      parseFloat(formDataKoBidding.max_cpc) <
                        (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                        parseFloat(formDataKoBidding.max_cpc) > 50
                        ? `Value must be between ${formDataKoBidding.platform === 'Google Ads' ? '0.01' : '0.05'
                        } and 50`
                        : ''
                    }
                  />
                </Box>
              </ComponentBlock>
            </>
          )}

          {/* Target CPA / Max Conversions (Google Ads) */}
          {['Target CPA', 'Maximize Conversions'].includes(formDataKoBidding.bidding_strategy_type) &&
            formDataKoBidding.platform === 'Google Ads' && (
              <>
                <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group">
                  <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      disableClearable
                      multiple={false}
                      noOptionsText="No options"
                      options={adGroupOptionsKoBiddingTargetMaximize || []}
                      getOptionLabel={(option: any) => option.ad_group || ''}
                      value={
                        adGroupOptionsKoBiddingTargetMaximize.find(
                          (opt: any) => opt.ad_group_id === formDataKoBidding.ad_group_id
                        ) || null
                      }
                      onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Ad Group"
                          placeholder="Select an ad group"
                          error={adGroupKoBiddingError}
                          helperText={adGroupKoBiddingError ? 'At least one ad group is required' : ''}
                        />
                      )}
                    />
                  </Box>
                </ComponentBlock>

                <ComponentBlock sx={{ marginTop: 3 }} title="Target CPA (0.05 - 50)">
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Target CPA"
                      type="text"
                      value={formDataKoBidding.target_cpa}
                      onChange={handleTargetCPAChange}
                      fullWidth
                      error={
                        parseFloat(formDataKoBidding.target_cpa) < 0.05 ||
                        parseFloat(formDataKoBidding.target_cpa) > 50
                      }
                      helperText={
                        parseFloat(formDataKoBidding.target_cpa) < 0.05 ||
                          parseFloat(formDataKoBidding.target_cpa) > 50
                          ? 'Value must be between 0.05 and 50'
                          : ''
                      }
                    />
                  </Box>
                </ComponentBlock>

                <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Advanced Settings (optionnal)">
                  <Box sx={{ width: '100%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formDataKoBidding.targetCpaVariation)}
                          onChange={(e) =>
                            setFormDataKoBidding((prev: any) => ({
                              ...prev,
                              targetCpaVariation: e.target.checked
                            }))
                          }
                        />
                      }
                      label="Enable Target CPA variation"
                    />
                  </Box>

                  <ComponentBlock sx={{ marginTop: 3 }} title="Max CPC 0.01 - 50)">
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        variant="outlined"
                        placeholder="Max CPC"
                        type="text"
                        value={formDataKoBidding.max_cpc_portfolio_strategy || ''}
                        onChange={(event) => handleDecimalPortFolioStratChange(event.target.value)}
                        fullWidth
                        error={
                          parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) < 0.01 ||
                          parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) > 50
                        }
                        helperText={
                          parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) < 0.01 ||
                            parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) > 50
                            ? 'Value must be between 0.05 and 50'
                            : ''
                        }
                      />
                    </Box>
                  </ComponentBlock>
                </ComponentBlock>
              </>
            )}

          {/* Target CPA / Max Conversions (Bing Ads) */}
          {['Target CPA', 'Maximize Conversions'].includes(formDataKoBidding.bidding_strategy_type) &&
            formDataKoBidding.platform === 'Bing Ads' && (
              <>
                {formDataKoBidding.bidding_strategy_type === "Target CPA" &&
                  <ComponentBlock sx={{ marginTop: 3 }} title="Target CPA (0.05 - 50)">
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        variant="outlined"
                        placeholder="Target CPA"
                        type="text"
                        value={formDataKoBidding.target_cpa}
                        onChange={handleTargetCPAChange}
                        fullWidth
                        error={
                          parseFloat(formDataKoBidding.target_cpa) < 0.05 ||
                          parseFloat(formDataKoBidding.target_cpa) > 50
                        }
                        helperText={
                          parseFloat(formDataKoBidding.target_cpa) < 0.05 ||
                            parseFloat(formDataKoBidding.target_cpa) > 50
                            ? 'Value must be between 0.05 and 50'
                            : ''
                        }
                      />
                    </Box>
                  </ComponentBlock>
                }

                <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Advanced Settings (optionnal)">
                  {['Target CPA'].includes(formDataKoBidding.bidding_strategy_type) &&
                    <Box sx={{ width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formDataKoBidding.targetCpaVariation)}
                            onChange={(e) =>
                              setFormDataKoBidding((prev: any) => ({
                                ...prev,
                                targetCpaVariation: e.target.checked
                              }))
                            }
                          />
                        }
                        label="Enable Target CPA variation"
                      />
                    </Box>
                  }

                  <ComponentBlock sx={{ marginTop: formDataKoBidding.bidding_strategy_type === "Target CPA" ? 3 : 0 }} title="Max CPC 0.05 - 50)">
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        variant="outlined"
                        placeholder="Max CPC"
                        type="text"
                        value={formDataKoBidding.max_cpc || ''}
                        onChange={(event) => handleDecimalChange(event.target.value)}
                        fullWidth
                        error={
                          parseFloat(formDataKoBidding.max_cpc) < 0.05 ||
                          parseFloat(formDataKoBidding.max_cpc) > 50
                        }
                        helperText={
                          parseFloat(formDataKoBidding.max_cpc) < 0.05 ||
                            parseFloat(formDataKoBidding.max_cpc) > 50
                            ? 'Value must be between 0.05 and 50'
                            : ''
                        }
                      />
                    </Box>
                  </ComponentBlock>
                </ComponentBlock>
              </>
            )}

          {/* Target ROAS / Max Conversion Value */}
          {['Target ROAS', 'Maximize Conversion Value'].includes(formDataKoBidding.bidding_strategy_type) && (
            <>
              {formDataKoBidding?.platform === "Google Ads" &&
                <ComponentBlock sx={{ marginTop: 2 }} title="Ad Group Selection">
                  <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      disableClearable
                      multiple={false}
                      noOptionsText="No options"
                      options={adGroupOptionsKoBiddingTargetMaximize || []}
                      getOptionLabel={(option: any) => option.ad_group || ''}
                      value={
                        adGroupOptionsKoBiddingTargetMaximize.find(
                          (opt: any) => opt.ad_group_id === formDataKoBidding.ad_group_id
                        ) || null
                      }
                      onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Ad Group"
                          placeholder="Select an ad group"
                          error={adGroupKoBiddingError}
                          helperText={adGroupKoBiddingError ? 'At least one ad group is required' : ''}
                        />
                      )}
                    />
                  </Box>
                </ComponentBlock>
              }

              <ComponentBlock sx={{ marginTop: 3 }} title="Target ROAS (1% - 5000%)">
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Target ROAS"
                    type="text"
                    value={formDataKoBidding.target_roas || ''}
                    onChange={(event) => handleTargetRoasMaximizeChange(event.target.value)}
                    error={targetRoasError}
                    helperText={targetRoasError ? 'La valeur doit être entre 1% et 5000%' : ''}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              backgroundColor: '#37424d',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '2px 10px 2px 10px',
                              borderRadius: '3px'
                            }}
                          >
                            <Typography sx={{ color: 'white', fontWeight: 600 }}>%</Typography>
                          </Box>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </ComponentBlock>

              <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Advanced Settings (optionnal)">
                <Box sx={{ width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(formDataKoBidding.targetRoasVariation)}
                        onChange={(e) =>
                          setFormDataKoBidding((prev: any) => ({
                            ...prev,
                            targetRoasVariation: e.target.checked
                          }))
                        }
                      />
                    }
                    label="Enable Target ROAS variation"
                  />
                </Box>

                <ComponentBlock
                  sx={{ marginTop: 3 }}
                  title={`Max CPC (${formDataKoBidding.platform === 'Google Ads' ? '0.01' : '0.05'
                    } - 50)`}
                >
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Max CPC"
                      type="text"
                      value={formDataKoBidding.max_cpc_portfolio_strategy || ''}
                      onChange={(event) => handleMaxCPCMaximizePortfolioStartegy(event.target.value)}
                      fullWidth
                      error={
                        parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) <
                        (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                        parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) > 50
                      }
                      helperText={
                        parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) <
                          (formDataKoBidding.platform === 'Google Ads' ? 0.01 : 0.05) ||
                          parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) > 50
                          ? `Value must be between ${formDataKoBidding.platform === 'Google Ads' ? '0.01' : '0.05'
                          } and 50`
                          : ''
                      }
                    />
                  </Box>
                </ComponentBlock>
              </ComponentBlock>
            </>
          )}
        </>
      )}

      {/* -------------------------------------------------------------------------- */}
      {/* MODE AUTO                                                                  */}
      {/* -------------------------------------------------------------------------- */}
      {selection === 'Search' && subSelection === 'Ko Bidding' && koBiddingMode === 'Auto' && (
        <>
          {/* Choix du Type (Blended / Ad) */}
          <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Type">
            <Box sx={{ width: '100%' }}>
              <Autocomplete
                disableClearable
                options={['Blended', 'Ad']}
                value={formDataKoBidding.type || 'Blended'}
                onChange={(event, newValue) => {
                  setFormDataKoBidding((prev: any) => ({
                    ...prev,
                    type: newValue
                  }))
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Type" placeholder="Select a type" />
                )}
              />
            </Box>
          </ComponentBlock>

          {/* Platform : Google Ads (non modifiable) */}
          <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Platform">
            <Box sx={{ width: '100%' }}>
              <TextField
                variant="outlined"
                label="Platform"
                value="Google Ads"
                disabled
                fullWidth
              />
            </Box>
          </ComponentBlock>

          {/* Account */}
          <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Account">
            <Box sx={{ width: '100%' }}>
              <Autocomplete
                disableClearable
                options={isLoadingProfileKoBidding ? [] : profileKoBiddingOptions || []}
                value={
                  profileKoBiddingOptions.find(
                    (option: any) => option.profile === formDataKoBidding.account
                  ) || null
                }
                onChange={handleAccountKoBiddingChange}
                getOptionLabel={(option: any) => option.profile}
                noOptionsText={isLoadingProfileKoBidding ? 'Loading...' : 'No options'}
                loading={isLoadingProfileKoBidding}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Account"
                    placeholder="Select an account"
                    error={profileError}
                    helperText={profileError ? 'At least one account is required' : ''}
                  />
                )}
              />
            </Box>
          </ComponentBlock>

          {/* Campaign (on filtre sur Max CPC seulement) */}
          {formDataKoBidding.account && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Campaign">
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  disableClearable
                  options={(campaignOptionsKoBidding || []).filter(
                    (c: any) => c.bidding_strategy_type === 'Max CPC'
                  )}
                  getOptionLabel={(option: any) => option.campaign_name || ''}
                  value={
                    (campaignOptionsKoBidding || []).find(
                      (opt: any) => opt.campaign_name === formDataKoBidding.campaign_name
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleCampaignKoBiddingChange(newValue)
                  }}
                  noOptionsText={isLoadingCampaignsKoBidding ? 'Loading...' : 'No options'}
                  loading={isLoadingCampaignsKoBidding}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Campaign"
                      placeholder="Select a campaign"
                      error={campaignKoBiddingError}
                      helperText={campaignKoBiddingError ? 'Campaign is required' : ''}
                    />
                  )}
                />
              </Box>
            </ComponentBlock>
          )}

          {/* Ad Group */}
          {formDataKoBidding.campaign_name?.length !== 0 && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Ad Group">
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  disableClearable
                  options={isLoadingAdGroupKoBidding ? [] : adGroupOptionsKoBiddingAuto || []}
                  getOptionLabel={(option: any) => option.ad_group || ''}
                  value={
                    (adGroupOptionsKoBiddingAuto || []).find(
                      (opt: any) => opt.ad_group_id === formDataKoBidding.ad_group_id
                    ) || null
                  }
                  onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)}
                  noOptionsText="No options"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Ad Group"
                      placeholder="Select an ad group"
                      error={adGroupKoBiddingError}
                      helperText={adGroupKoBiddingError ? 'At least one ad group is required' : ''}
                    />
                  )}
                />
              </Box>
            </ComponentBlock>
          )}

          {/* Keyword */}
          {formDataKoBidding.ad_group_id && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Keyword">
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  disableClearable
                  options={isLoadingKoBiddingKeywords ? [] : keywordKoBiddingAutoOptions || []}
                  getOptionLabel={(option: any) => option.keyword_bid || ''}
                  value={
                    keywordKoBiddingAutoOptions.find(
                      (opt: any) => opt.keyword_bid === formDataKoBidding.keyword_bid
                    ) || null
                  }
                  onChange={handleKeywordKoBiddingChange}
                  noOptionsText={isLoadingKoBiddingKeywords ? 'Loading...' : 'No options'}
                  loading={isLoadingKoBiddingKeywords}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Keyword"
                      placeholder="Select a keyword"
                      error={keywordKoBiddingError}
                      helperText={keywordKoBiddingError ? 'At least one keyword is required' : ''}
                    />
                  )}
                />
              </Box>
            </ComponentBlock>
          )}

          {/* Match Type = "Exact" (disabled) */}
          {formDataKoBidding.keyword_bid && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Match Type">
              <Box sx={{ width: '100%' }}>
                <TextField variant="outlined" label="Match Type" value="Exact" disabled fullWidth />
              </Box>
            </ComponentBlock>
          )}

          {/* Input Parameters */}
          {formDataKoBidding.keyword_bid && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Input Parameters">
              {/* Aggressivity */}
              <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Aggressivity Level">
                <Box sx={{ width: '100%' }}>
                  <Autocomplete
                    disableClearable
                    options={['Low', 'Medium', 'High', 'Custom']}
                    value={aggressivity}
                    onChange={(event, newValue) => setAggressivity(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Aggressivity Level" />
                    )}
                  />
                </Box>
              </ComponentBlock>

              {/* Initial Max CPC (disabled) */}
              {formDataKoBidding.initial_max_cpc && (
                <ComponentBlock sx={{ marginTop: 1, mb: 1 }} title="Initial Max CPC">
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      variant="outlined"
                      label="Initial Max CPC"
                      value={formDataKoBidding.initial_max_cpc || ''}
                      disabled
                      fullWidth
                    />
                  </Box>
                </ComponentBlock>
              )}

              {/* Initial Avg CPC (disabled) */}
              {formDataKoBidding.initial_avg_cpc != null && (
                <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Initial Avg. CPC">
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      variant="outlined"
                      label="Initial Avg. CPC"
                      value={
                        typeof formDataKoBidding.initial_avg_cpc === 'number'
                          ? formDataKoBidding.initial_avg_cpc.toFixed(2)
                          : ''
                      }
                      disabled
                      fullWidth
                    />
                  </Box>
                </ComponentBlock>
              )}

              {/* Max CPC Target Reduction (%) */}
              <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Max CPC Target Reduction (0% - 100%)">
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    label="Max CPC Target Reduction (0% - 100%)"
                    type="number"
                    value={formDataKoBidding.max_cpc_target_reduction}
                    onChange={handleMaxCpcChange}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              backgroundColor: '#37424d',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '2px 10px 2px 10px',
                              borderRadius: '3px'
                            }}
                          >
                            <Typography sx={{ color: 'white', fontWeight: 600 }}>%</Typography>
                          </Box>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </Box>
              </ComponentBlock>

              {/* Target Max CPC (0.01 - 50), lu-seulement */}
              <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Target Max CPC (0.01 - 50)">
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    label="Target Max CPC"
                    value={computeTargetMaxCpc()}
                    error={isError}
                    helperText={
                      isError ? 'Modify the Max CPC Target Reduction to enter a valid value' : ''
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              backgroundColor: '#37424d',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '2px 10px 2px 10px',
                              borderRadius: '3px',
                              py: 0.75
                            }}
                          >
                            <Iconify
                              sx={{ color: 'white!important' }}
                              icon={`cryptocurrency:${workspace?.currency}`}
                            />
                          </Box>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </ComponentBlock>

              {/* Durée de la phase d'optimisation (0 - 365) */}
              <ComponentBlock
                sx={{ marginTop: 3, mb: 1 }}
                title="Duration of the optimization phase (0 - 365 days)"
              >
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Duration of the optimization phase"
                    type="number"
                    value={formDataKoBidding.optimization_phase_duration || ''}
                    onChange={(e) =>
                      setFormDataKoBidding((prev: any) => ({
                        ...prev,
                        optimization_phase_duration: e.target.value
                      }))
                    }
                    fullWidth
                    error={
                      formDataKoBidding.optimization_phase_duration !== '' &&
                      (parseInt(formDataKoBidding.optimization_phase_duration, 10) < 0 ||
                        parseInt(formDataKoBidding.optimization_phase_duration, 10) > 365)
                    }
                    helperText={
                      formDataKoBidding.optimization_phase_duration !== '' &&
                        (parseInt(formDataKoBidding.optimization_phase_duration, 10) < 0 ||
                          parseInt(formDataKoBidding.optimization_phase_duration, 10) > 365)
                        ? 'La durée doit être comprise entre 0 et 365 jours.'
                        : ''
                    }
                    inputProps={{ min: 0, max: 365 }}
                  />
                </Box>
              </ComponentBlock>

              {/* Max CPC daily reduction (0.01 - 50), read only */}
              <ComponentBlock
                sx={{ marginTop: 3, mb: 1 }}
                title="Max CPC daily reduction (0.01 - 50)"
              >
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    label="Max CPC daily reduction"
                    value={computeMaxCpcDailyReduction()}
                    error={isDailyReductionMsgVisible}
                    helperText={
                      isDailyReductionMsgVisible
                        ? 'Modify the optimization phase duration to enter a valid value'
                        : ''
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Box>
              </ComponentBlock>

              {/* Accepted Variation Threshold (0 - 10) */}
              <ComponentBlock
                sx={{ marginTop: 3, mb: 1 }}
                title="Accepted variation threshold (0 - 10 points)"
              >
                <Box sx={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Accepted variation threshold"
                    type="number"
                    value={formDataKoBidding.accepted_variation_threshold || ''}
                    onChange={(e) => {
                      const val = e.target.value
                      const numericValue = parseFloat(val)
                      setFormDataKoBidding((prev: any) => ({
                        ...prev,
                        accepted_variation_threshold: val
                      }))
                      if (val === '' || isNaN(numericValue)) {
                        setErrorAcceptedVariation('')
                      } else if (numericValue < 0 || numericValue > 10) {
                        setErrorAcceptedVariation('The value must be between 0 and 10 points.')
                      } else {
                        setErrorAcceptedVariation('')
                      }
                    }}
                    fullWidth
                    error={Boolean(errorAcceptedVariation)}
                    helperText={errorAcceptedVariation}
                    inputProps={{ min: 0, max: 10 }}
                  />
                </Box>
              </ComponentBlock>
            </ComponentBlock>
          )}

          {/* Launching date */}
          {formDataKoBidding.keyword_bid && (
            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Launching date">
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  type="date"
                  value={formDataKoBidding.launching_date || ''}
                  onChange={(e) => {
                    setFormDataKoBidding((prev: any) => ({
                      ...prev,
                      launching_date: e.target.value
                    }))
                  }}
                  fullWidth
                  inputProps={{
                    min: new Date().toISOString().split('T')[0]
                  }}
                />
              </Box>
            </ComponentBlock>
          )}
        </>
      )}
    </>
  )
}
