import { Box, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";
import DataGridCustomPresence from "src/components/data-grid/data-grid-custom-presence";
import Iconify from "src/components/iconify";
import { useGetCountriesCompetitorQuery, useGetDevicesCompetitorQuery, useGetKeywordsCompetitorQuery, useGetPlatformsCompetitorQuery, useGetPresenceQuery } from "src/context/api/competitorsMonitoring/api";
import { useDispatch, useSelector } from "react-redux";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useEffect } from "react";
import { setFilterCountry, setFilterDevice, setFilterKeyword, setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword, setFilterLoadedPlatform, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useLocales } from "src/locales";
import { usePreviousLocation } from "src/components/previousLocationContext";

export default function CompetitorFocus() {
    const { t } = useLocales();
    const lgUp = useResponsive('up', 'lg');
    const theme = useTheme();
    const filterStore = useSelector((state: any) => state.filters);
    const workspace: any = useCurrentWorkspace();
    const dispatch = useDispatch();

    const prevLocation = usePreviousLocation();

    useEffect(() => {
        if (prevLocation !== "/dash/competitors-monitoring") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
        }
    }, [prevLocation])

    useEffect(() => {
        if (prevLocation === "/dash/brand-wayback") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
            dispatch(setFilterDevice([]))
            dispatch(setFilterLoadedDevice([]))
            dispatch(setFilterCountry([]))
            dispatch(setFilterLoadedCountry([]))
        }
    }, [prevLocation])

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

    const { data: platforms, error: platformsError, isFetching: isFetchingPlatforms } = useGetPlatformsCompetitorQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
    },
        { skip: skipQuery }
    );

    useEffect(() => {
        if (platforms)
            dispatch(setFilterLoadedPlatform(platforms))
    }, [platforms])


    const { data: countries, error: countriesError } = useGetCountriesCompetitorQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: true,
    });

    const { data: keywords, error: keywordsError, isFetching: isFetchingKeywords } = useGetKeywordsCompetitorQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));

        if (!isFetchingKeywords) {
            dispatch(setFilterLoadedKeyword(keywords?.filters?.keywords))
        }
    }, [keywords, workspace])


    const { data: devices, error: devicesError } = useGetDevicesCompetitorQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: true,
    });

    const { data: presenceData, error: dataPresenceError, isFetching: isFetchingDataPresence } = useGetPresenceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: Array.isArray(filterStore.devices) ? filterStore.devices.map((device: any) => device.toLowerCase()) : filterStore.devices?.toLowerCase(),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: workspace?.url,
        platform: filterStore?.platforms
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: true,
    });

    const dataPresence = presenceData?.presence?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceRate: item?.ratio_present_scrap * 100,
        device: item?.device,
        country: item?.country,
    })) || [];

    useEffect(() => {
        if (presenceData?.minDatePicker) {
            dispatch(setFilterMinDatePicker(presenceData.minDatePicker[0]?.first_date));
        }
    }, [presenceData, dispatch]);

    useEffect(() => {
        if (countries) {
            dispatch(setFilterLoadedCountry(countries?.countries));
        }
    }, [countries]);

    useEffect(() => {
        if (devices) {
            dispatch(setFilterLoadedDevice(devices?.devices));
        }
    }, [devices]);

    return (
        <>
            <Box sx={{ marginTop: 1, px: 3 }}>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                            borderRadius: 2,
                            marginBottom: 1,
                            width: '100%',
                            height: 830
                        }}
                    >
                        {isFetchingDataPresence || (!dataPresence && !dataPresenceError)
                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                <>
                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                    <Typography color={"success.main"}>Active</Typography>
                                </>
                            </Skeleton>
                            : <div>
                                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                        {t('dashboard.competitorsOverview')}
                                    </Typography>

                                </div>

                                <Box sx={{ height: 600, }}>
                                    <DataGridCustomPresence pageSize={10} data={dataPresence} />
                                </Box>
                            </div>
                        }
                    </Paper>
                </Grid>
            </Box>
        </>
    )
}
